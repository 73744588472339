import {
  ConnectionQuality,
  ConnectionState,
  DataPacket_Kind,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  Track,
  LogLevel,
  setLogLevel,
  Participant,
} from "livekit-client-legacy";
import * as Sentry from "@sentry/browser";
import { datadogRum } from "@datadog/browser-rum";
import _ from "lodash";

import {
  SkybusBroadcast,
  Ping,
} from "@skydio/pbtypes/pbtypes/experimental/webrtc_c_impl/livekit_transport_pb";
import { multipart_msg_t } from "@skydio/lcm/types/skybus_tunnel/multipart_msg_t";

import { ChannelStats } from "@skydio/pbtypes/pbtypes/experimental/webrtc_c_impl/webrtc_stats_pb";
import { RemoteStreamingStats } from "@skydio/pbtypes/pbtypes/mobile/phone/mobile_pb";
import { PilotRequest } from "@skydio/pbtypes/pbtypes/vehicle/pilot_service/pilot_pb";
import { DataChannelPacket } from "@skydio/pbtypes/pbtypes/cloud/services/skygateway/cloud_node_pb";

import { TransportLayer } from "../base";
import { LivekitTransportArgs, RemoteDeviceType, LivekitSkybusStatsReport } from "../types";
import { USER_CAMERA_STREAM_PB } from "../utils";
import LivekitConnector from "./livekit_connector";
import { UserPacket } from "livekit-client-legacy/dist/src/proto/livekit_models_pb";
import { PublishOptions, QOS } from "../../types";
import { logger } from "../../logger";

/**
 * Skybus LivekitConnector
 *
 * Javascript API for communicating with the Livekit.
 */

const __LIVEKIT_PING_CHANNEL = "__LIVEKIT_PING_CHANNEL";
const PHONE_STREAMING_STATUS_PB = "PHONE_STREAMING_STATUS_PB";
const TOPIC_REGEX = /^([^/]+)\/(.*)/;

export enum ProtocolChannel {
  PILOT_REQUEST = "_PILOT_PILOT_PILOT",
  PING = "__PING_PING_PING",
}

export const PROTOCOL_CHANNELS: Readonly<Set<string>> = new Set(Object.values(ProtocolChannel));

export const CHANNEL_PACKET_TYPES = Object.entries(DataChannelPacket.Type).reduce(
  (acc, [key, value]) => {
    acc.set(value, key);
    return acc;
  },
  new Map<string | DataChannelPacket.Type, string>()
);

/**
 * Protocol messages that can be sent over LiveKit data channels.
 */
export const ProtocolChannelDefinitions = {
  /**
   * Requests pilot access. Communication tunnels are only setup between a pilot and vehicle, so
   * requesting needs to occur using a protocol message to ensure proper routing prior to becoming
   * pilot.
   */
  PILOT_REQUEST: {
    channel: ProtocolChannel.PILOT_REQUEST,
    type: PilotRequest.deserializeBinary,
  },
};

// convert QOS -> DataPacket_Kind. Assume reliable if not specified
function qosToKind(qos?: QOS): DataPacket_Kind {
  return qos === QOS.RELIABLE__DO_NOT_USE ? DataPacket_Kind.RELIABLE : DataPacket_Kind.LOSSY;
}

export default class LivekitTransportLayer extends TransportLayer {
  public lastPublishPeerChannelStatsAsObject?: ChannelStats.AsObject;
  public room?: Room;
  public clientId: string;
  public connectionState!: ConnectionState;
  public videoTracks: Map<string, Track>;
  public livekitSkybusStatsReport: LivekitSkybusStatsReport;
  public lastReceivedLivekitData: number;
  public trackSidToTrackNameMap: Map<string, string>;
  private userUuid: string;
  private url: string;
  private token: string;
  private _isConnected: boolean;
  private videoOutputElement?: HTMLVideoElement;
  private videoStream?: MediaStream;
  private pingOfferSeqId: number;
  private isTeleop?: boolean;
  private handleMultipleParticipants?: () => void;
  private onRoomConnect?: (clientId: string, room: Room) => void;
  private onRoomDisconnect?: () => void;
  private vehicleSid?: string;
  private vehicleId?: string;
  private dockSid?: string;
  private dockId?: string;
  private onDetectedVehicleNotInRoom?: () => void;
  private onDetectedVehicleInRoom?: () => void;
  private onDetectedDockInRoom?: () => void;
  private onDetectedDockNotInRoom?: () => void;
  private onRoomConnectError?: (error: string) => void;
  private onRoomReconnecting?: () => void;
  private onRoomReconnected?: () => void;
  private onConnectionQualityChanged?: (
    quality: ConnectionQuality,
    participant: Participant
  ) => void;
  private onConnectionStateChanged?: (connectionState: ConnectionState) => void;
  private onVideoTrackMapChanged?: (videoTrackMap: Map<string, Track>) => void;
  private onParticipantsChanged?: (
    participants: Map<string, RemoteParticipant> | undefined
  ) => void;

  private connectors: Map<string, LivekitConnector>;

  /**
   * @param {string} userUuid - uuid of current user
   * @param {string} vehicleId - vehicleId of the primary vehicle showing video
   * @param {boolean} isTeleop - boolean to identify if the current user is in a teleop usecase
   * @param {string} url - livekit url of the stream
   * @param {string} token - livekit auth token
   * @param {function} onTrackUnsubscribed - callback when participant unsubscribes from track
   * @param {function} onTrackUnsubscribed - callback when participant subscribes to track
   * @param {function} handleMultipleParticipants - callback when checking if there are multiple viewers in room
   * @param {function} onRoomConnect - callback when local participant connects to room
   * @param {function} onRoomConnect - callback when local participant disconnects from room
   * @param {function} onDetectedVehicleInRoom - callback when the targeted vehicle reconnects to room
   * @param {function} onDetectedVehicleNotInRoom - callback when the targeted vehicle disconnects from room
   * @param {function} onDetectedDockInRoom - callback when the relevant dock disconnects from room
   * @param {function} onDetectedDockNotInRoom - callback when the relevant dock disconnects from room
   * @param {function} onRoomReconnecting - callback when local participant is attempting to reconnect to the room
   * @param {function} onRoomReconnected - callback when local participant has successfully reconnected
   * @param {function} onConnectionQualityChanged - callback when local participant connection quality changes
   * @param {function} onConnectionStateChanged - callback when local participant connection state changes
   * @param {function} onVideoTrackMapChanged - callback when the video track map changes
   * @param {function} onParticipantsChanged - callback when the remote participants in the room change
   * @param {ISkybusOptions} options {
   *   onStateChange : callback invoked when connection status changes
   *           quiet : do not print anything
   *         verbose : print detailed information about everything
   *    resetOnClose : perform the reset() function on close instead of on connect
   * }
   */
  constructor({
    userUuid,
    url,
    token,
    vehicleId,
    isTeleop,
    handleMultipleParticipants,
    onRoomConnect,
    onRoomDisconnect,
    onDetectedVehicleInRoom,
    onDetectedVehicleNotInRoom,
    onDetectedDockInRoom,
    onDetectedDockNotInRoom,
    onRoomConnectError,
    onRoomReconnecting,
    onRoomReconnected,
    onConnectionQualityChanged,
    onConnectionStateChanged,
    onVideoTrackMapChanged,
    onParticipantsChanged,
    options,
  }: LivekitTransportArgs) {
    super(options);
    this.userUuid = userUuid;
    this.url = url;
    this.token = token;
    this.vehicleId = vehicleId;
    this.isTeleop = isTeleop ?? false;
    this._isConnected = false;
    this.pingOfferSeqId = 0;
    this.lastReceivedLivekitData = -1;
    this.handleMultipleParticipants = handleMultipleParticipants;
    this.onRoomConnect = onRoomConnect;
    this.onRoomDisconnect = onRoomDisconnect;
    this.onDetectedVehicleInRoom = onDetectedVehicleInRoom;
    this.onDetectedVehicleNotInRoom = onDetectedVehicleNotInRoom;
    this.onDetectedDockInRoom = onDetectedDockInRoom;
    this.onDetectedDockNotInRoom = onDetectedDockNotInRoom;
    this.videoTracks = new Map();
    this.trackSidToTrackNameMap = new Map();
    this.onRoomConnectError = onRoomConnectError;
    this.onRoomReconnecting = onRoomReconnecting;
    this.onRoomReconnected = onRoomReconnected;
    this.onConnectionQualityChanged = onConnectionQualityChanged;
    this.onConnectionStateChanged = onConnectionStateChanged;
    this.onVideoTrackMapChanged = onVideoTrackMapChanged;
    this.onParticipantsChanged = onParticipantsChanged;
    this.livekitSkybusStatsReport = {
      sendStats: {},
      sendBeforeReadyStats: {},
      receiveStats: {},
    };

    this.connectors = new Map();

    this.reset();
  }

  private reset() {
    this.pingOfferSeqId = 0;

    this.clientId = this.userUuid;
    this.connectionState = ConnectionState.Disconnected;

    this.connectors.forEach(connector => {
      connector.reset();
    });

    this.handleRoomParticipants();
  }

  get isConnected() {
    return this._isConnected;
  }

  /**
   * handles track updates, updating video track and sid to name mappings.
   * we handle updates using a shared method and check participant tracks off the room object
   * because of an issue with the legacy livekit client we're using. the client doesn't seem to
   * fire track publish/unpublish/subscribe/unsubscribe events in the correct order, but the room
   * consistently has the correct metadata.
   */
  private handleTrackUpdate(room: Room) {
    const nextVideoTracks = new Map<string, Track>();
    const nextTrackSidToTrackNameMap = new Map<string, string>();
    room.participants.forEach(participant => {
      participant.getTracks().forEach(publication => {
        if (publication.isSubscribed && publication.track != null) {
          nextVideoTracks.set(publication.trackName, publication.track);
          nextTrackSidToTrackNameMap.set(publication.trackSid, publication.trackName);
        }
      });
    });

    this.trackSidToTrackNameMap = nextTrackSidToTrackNameMap;
    if (!_.isEqual(nextVideoTracks, this.videoTracks)) {
      this.videoTracks = nextVideoTracks;
      this.onVideoTrackMapChanged?.(this.videoTracks);
    }
  }

  public async connect() {
    try {
      const room = new Room({ disconnectOnPageLeave: false });
      // NOTE(ryan.fong): the following line is for showing the logs from the livekit client sdk,
      // leaving this as warn since debug is noisy
      setLogLevel(LogLevel.warn);
      this.reset();

      // define room event handlers
      room.on(RoomEvent.TrackSubscribed, () => this.handleTrackUpdate(room));
      room.on(RoomEvent.TrackUnsubscribed, () => this.handleTrackUpdate(room));
      room.on(RoomEvent.ParticipantDisconnected, () => {
        this.handleRoomParticipants();
      });
      room.on(RoomEvent.ParticipantConnected, () => {
        this.handleRoomParticipants();
      });
      room.on(RoomEvent.Disconnected, () => {
        logger.info("user disconnected");
        this.onRoomDisconnect?.();
      });
      room.on(RoomEvent.Reconnecting, () => {
        logger.info("user reconnecting");
        this.onRoomReconnecting?.();
      });
      room.on(RoomEvent.Reconnected, () => {
        logger.info("user reconnected");
        this.onRoomReconnected?.();
        this.onRoomConnect?.(this.clientId, room);
        this.handleRoomParticipants();
      });
      room.on(RoomEvent.ConnectionQualityChanged, (quality, participant) => {
        logger.debug("connection quality changed", { identity: participant?.identity, quality });
        this.onConnectionQualityChanged?.(quality, participant);
      });
      room.on(RoomEvent.ConnectionStateChanged, state => {
        logger.info("connection state changed", { state });
        this.onConnectionStateChanged?.(state);
      });
      // @ts-ignore -- NOTE(trey): this is yarn patch'd, and the type no longer comes through correctly
      room.on(RoomEvent.DataReceived, this.handleDataPacket);

      // connect to the room
      await room.connect(this.url, this.token, { autoSubscribe: true });
      this.room = room;
      logger.info("connected to room", {
        name: room.name,
        numParticipants: room.participants.size,
      });

      const idArr = this.room.localParticipant.identity.split("-");
      const nonce = idArr[idArr.length - 1];
      this.clientId = `${this.userUuid}-${nonce}`;
      if (this.checkMultipleParticipants()) {
        if (this.handleMultipleParticipants) this.handleMultipleParticipants();
      }
      this._isConnected = true;
      this.connectionState = ConnectionState.Connected;
      this.connectors.forEach(connector => {
        connector.startPacketizer();
      });
      this.handleRoomParticipants();
      this.onRoomConnect?.(this.clientId, room);
    } catch (err: any) {
      logger.error("failed to connect to room", { err });
      Sentry.captureException(err);
      datadogRum.addError(err, { team: { name: "dro-streaming" } });
      this.onRoomConnectError?.("Unable to connect to the server");
    }
  }

  public setVideoOutputElement(video: HTMLVideoElement) {
    // this follows the pattern in the Webrtc Connector
    this.videoOutputElement = video;
    // if we got the video stream before setting the video element
    if (this.videoStream) {
      this.videoOutputElement.srcObject = this.videoStream;
    }
  }

  public waitForConnect(): Promise<{}> {
    throw new Error(
      "Wrong usage. livekit connection is async, just call await on the connect method."
    );
  }

  public async getPublisherStats(): Promise<RTCStatsReport | undefined> {
    return await this.room?.engine?.publisher?.pc?.getStats();
  }

  public async getSubscriberStats(): Promise<RTCStatsReport | undefined> {
    return await this.room?.engine?.subscriber?.pc?.getStats();
  }

  public async publishBroadcastMessage(data: Uint8Array, broadcastChannel: string) {
    if (this.room?.state !== ConnectionState.Connected) {
      this.addMessageToLivekitSkybusStatsReport(
        "sendBeforeReadyStats",
        broadcastChannel,
        data.byteLength
      );
      return;
    }

    this.addMessageToLivekitSkybusStatsReport("sendStats", broadcastChannel, data.byteLength);

    this.room?.localParticipant?.publishData(data, DataPacket_Kind.LOSSY, {
      topic: `${CHANNEL_PACKET_TYPES.get(DataChannelPacket.Type.BROADCAST)}/${broadcastChannel}`,
    });
  }

  public async publishProtocolMessage(
    destinationSid: string,
    data: Uint8Array,
    protocolChannel: string,
    options?: PublishOptions
  ) {
    if (this.room?.state !== ConnectionState.Connected) {
      this.addMessageToLivekitSkybusStatsReport(
        "sendBeforeReadyStats",
        protocolChannel,
        data.byteLength
      );
      return;
    }

    this.addMessageToLivekitSkybusStatsReport(
      "sendStats",
      protocolChannel,
      data.byteLength,
      destinationSid
    );
    this.room?.localParticipant.publishData(data, qosToKind(options?.qos), {
      destination: [destinationSid, protocolChannel],
      topic: `${CHANNEL_PACKET_TYPES.get(DataChannelPacket.Type.CONTROL)}/${protocolChannel}`,
    });
  }

  public async publishTunnelMessage(
    channel: string,
    destinationSid: string,
    data: Uint8Array,
    options?: PublishOptions
  ) {
    this.room?.localParticipant?.publishData(data, qosToKind(options?.qos), {
      destination: [destinationSid],
      topic: `${CHANNEL_PACKET_TYPES.get(DataChannelPacket.Type.TUNNEL)}/chunk`,
    });
  }

  // NOTE(sam): if we ever need to support some sort of multi-drone use case over livekit, the
  // following three functions are the first bit of API surface area to extend on

  public createConnector(deviceType: RemoteDeviceType): LivekitConnector {
    const connector = new LivekitConnector(this, deviceType);
    this.connectors.set(deviceType, connector);
    return connector;
  }

  public getDeviceSid(deviceType: RemoteDeviceType): string | undefined {
    if (deviceType === RemoteDeviceType.DOCK) {
      return this.dockSid;
    } else if (deviceType === RemoteDeviceType.VEHICLE) {
      return this.vehicleSid;
    }
  }

  public getTrackSid(name: string): string | undefined {
    for (const [trackSid, trackName] of this.trackSidToTrackNameMap) {
      if (trackName === name) {
        return trackSid;
      }
    }
  }

  public getTrackName(sid: string): string | undefined {
    return this.trackSidToTrackNameMap.get(sid);
  }

  private getConnectorBySid(sid: string): LivekitConnector | undefined {
    if (sid === this.vehicleSid) {
      return this.connectors.get(RemoteDeviceType.VEHICLE);
    }
    if (sid === this.dockSid) {
      return this.connectors.get(RemoteDeviceType.DOCK);
    }
  }

  public close() {
    this._isConnected = false;
    this.connectionState = ConnectionState.Disconnected;

    if (this.room) {
      this.room.removeAllListeners();
      this.room.disconnect();
    }

    this.reset();
    this.connectors.forEach(connector => {
      connector.stopPacketizer();
    });
  }

  public async sendPingOffer(timestamp: number): Promise<void> {
    // send ping offer via SkybusBroadcast
    const message = new SkybusBroadcast();
    message.setChannel(__LIVEKIT_PING_CHANNEL);
    const pingMessage = new Ping();
    pingMessage.setPingTime(timestamp);
    pingMessage.setType(Ping.Type.OFFER);
    pingMessage.setSeqId(this.pingOfferSeqId);
    this.pingOfferSeqId += 1;
    message.setEncodedMsg(pingMessage.serializeBinary());
    this.publishBroadcastMessage(message.serializeBinary(), __LIVEKIT_PING_CHANNEL);
    return;
  }

  private sendPingReply = (pingOfferMessage: Ping, destinationSid: string) => {
    const pingReplyMessage = new Ping();
    pingReplyMessage.setPingTime(pingOfferMessage.getPingTime());
    pingReplyMessage.setType(Ping.Type.REPLY);
    pingReplyMessage.setSeqId(pingOfferMessage.getSeqId()); // send same seqId of the offer in the reply
    this.publishProtocolMessage(
      destinationSid,
      pingReplyMessage.serializeBinary(),
      ProtocolChannel.PING,
      { qos: QOS.UNRELIABLE }
    );
  };

  private handleDataPacket = (userPacket: UserPacket, kind?: DataPacket_Kind, topic?: string) => {
    // check if we are receiving any data from the vehicle
    if (userPacket.participantSid === this.vehicleSid) {
      this.lastReceivedLivekitData = performance.now();
    }

    // if destinationSids.length === 2, means we got a protocol message
    // currently these are only being used for pings
    const topicMatches = topic?.match(TOPIC_REGEX);
    let topicType = DataChannelPacket.Type.UNKNOWN;
    let topicName = "";
    if (topicMatches) {
      const typeKey = topicMatches[1] as keyof typeof DataChannelPacket.Type;
      topicType = DataChannelPacket.Type[typeKey] ?? DataChannelPacket.Type.UNKNOWN;
      topicName = topicMatches[2];
    }

    if (userPacket.destinationSids?.length === 2 || topicType === DataChannelPacket.Type.CONTROL) {
      this.handleProtocolMessage(userPacket, topicName);
      return;
    }
    // if destinationSids.length === 1, means we got a tunnel message
    if (userPacket.destinationSids?.length === 1 || topicType === DataChannelPacket.Type.TUNNEL) {
      this.handleTunnelMessage(userPacket);
      return;
    }

    // NOTE (ryan.fong): I used try/catch in order
    // to avoid crashes in case we don't get a SkybusBroadcast or Ping message
    // It is expected that we should be getting SkybusBroadcast messages only, pings
    // are handled above
    try {
      this.handleBroadcastMessage(userPacket);
    } catch (e) {
      try {
        const ping = Ping.deserializeBinary(userPacket.payload);
        logger.debug("received ping reply with no sids", { ping: ping.toObject() });
      } catch (e) {
        Sentry.captureException(e);
        datadogRum.addError(e, { team: { name: "dro-streaming" } });
        logger.trace("received unidentified broadcast message", { topic: userPacket.topic });
      }
    }
  };

  private handleBroadcastMessage = (userPacket: UserPacket) => {
    const skybusBroadcast = SkybusBroadcast.deserializeBinary(userPacket.payload);

    const channel = skybusBroadcast.getChannel();
    const msg = skybusBroadcast.getEncodedMsg_asU8();

    this.addMessageToLivekitSkybusStatsReport(
      "receiveStats",
      channel,
      msg.byteLength,
      userPacket.participantSid
    );

    // decode livekit client stats
    if (channel === PHONE_STREAMING_STATUS_PB) {
      const remoteStreamingStats = RemoteStreamingStats.deserializeBinary(msg);
      // Currently we are only going to display the video channel stats from the publish peer
      const livekitClientStats = remoteStreamingStats.getLivekitStats();
      const publishPeer = livekitClientStats?.getPublishPeer();
      const videoChannelsList = publishPeer?.getVideoChannelsList();
      if (videoChannelsList?.[0])
        this.lastPublishPeerChannelStatsAsObject = videoChannelsList[0].toObject();
      logger.debug("latest remote streaming status", { status: remoteStreamingStats.toObject() });
      return;
    }

    if (channel === __LIVEKIT_PING_CHANNEL) {
      // handle ping offer
      const pingOffer = Ping.deserializeBinary(msg);
      if (pingOffer.getType() === Ping.Type.OFFER)
        this.sendPingReply(pingOffer, userPacket.participantSid);
      return;
    }

    const connector = this.getConnectorBySid(userPacket.participantSid);
    if (connector) {
      if (connector.handleMessage(channel, msg)) {
        return;
      }
    }

    logger.trace("got unrequested message", { channel });
  };

  private handleProtocolMessage(userPacket: UserPacket, protocolName: string) {
    // handle received protocol message
    // currently only being used for pings
    if (
      protocolName === ProtocolChannel.PING ||
      userPacket.destinationSids[1] === ProtocolChannel.PING
    ) {
      if (!this.livekitSkybusStatsReport.receiveStats[userPacket.participantSid]) {
        this.livekitSkybusStatsReport.receiveStats[userPacket.participantSid] = {};
      }
      this.addMessageToLivekitSkybusStatsReport(
        "receiveStats",
        __LIVEKIT_PING_CHANNEL,
        userPacket.payload.byteLength,
        userPacket.participantSid
      );
      this.handlePingReply(userPacket.payload, userPacket.participantSid);
    } else {
      logger.debug("received unexpected protocol message", {
        protocolName,
        destinationSids: userPacket.destinationSids,
      });
    }
  }

  private handleTunnelMessage(userPacket: UserPacket) {
    const message = userPacket.payload;
    const arrayBuffer = message.buffer.slice(
      message.byteOffset,
      message.byteLength + message.byteOffset
    );

    const wrappedMsg = new multipart_msg_t().decode(new Uint8Array(arrayBuffer));
    const { channel } = wrappedMsg;

    this.addMessageToLivekitSkybusStatsReport(
      "receiveStats",
      channel,
      userPacket.payload.byteLength,
      userPacket.participantSid
    );

    const connector = this.getConnectorBySid(userPacket.participantSid);
    connector?.handleTunnelData(arrayBuffer);
  }

  private handlePingReply = (message: Uint8Array, participantSid: string) => {
    // only handle ping replies if the participant is sharing video
    const participant = this.room?.participants.get(participantSid);
    if (participant && participant.videoTracks.size > 0) {
      const connector = this.getConnectorBySid(participant.sid);
      if (connector) {
        try {
          const ping = Ping.deserializeBinary(message);
          connector.recordRoundTripStatsFromPing(ping.getPingTime());
        } catch (e) {
          Sentry.captureException(e);
          datadogRum.addError(e, { team: { name: "dro-streaming" } });
        }
      }
    }
  };

  private checkMultipleParticipants = () => {
    // TODO (ryan.fong): check the participant id so that we know there is a vehicle or phone that
    // is streaming in the room
    // account for vehicle and dock also being in the room
    // filter for only participants with the VIEWER role
    const viewers = Array.from(this.room?.participants?.values()!).filter(
      (participant: RemoteParticipant) => participant.identity.split("-")[0] === "VIEWER"
    );
    if (viewers.length! >= 1) return true;
    return false;
  };

  // NOTE (ryan.fong): this is a hack to identify if the vehicle stream is being published from a phone
  private isMobile = (id: string) => {
    return id.startsWith("PUBLISHER-ANDROID");
  };

  private handleRoomParticipants() {
    let vehicleInRoom = false;
    let dockInRoom = false;

    this.room?.participants?.forEach((participant: RemoteParticipant, sid: string) => {
      // Identity will be in the format <device_role>-<device_type>-<skydio_device_id>, this line
      // removes the first two parts while keeping the device id intact in case it itself contains
      // dashes.
      const deviceId = participant.identity.split("-").slice(2).join("-");
      if (deviceId === this.vehicleId || this.isMobile(participant.identity)) {
        vehicleInRoom = true;
        if (sid !== this.vehicleSid) {
          this.onDetectedVehicleInRoom?.();
          this.vehicleSid = sid;
          logger.info("vehicle detected in room", {
            isTeleop: this.isTeleop,
            vehicleSid: this.vehicleSid,
            vehicleId: this.vehicleId,
          });
        }
        // TODO(sam): make this check better because we might get uuids starting with dc
      } else if (deviceId.startsWith("dc") || deviceId.startsWith("g4")) {
        dockInRoom = true;
        this.dockId = deviceId;
        if (sid !== this.dockSid) {
          this.onDetectedDockInRoom?.();
          this.dockSid = sid;
          logger.info("dock detected in room", {
            isTeleop: this.isTeleop,
            dockSid: this.dockSid,
            dockId: this.dockId,
          });
        }
      }
    });

    if (this.dockSid && !dockInRoom) {
      this.dockSid = "";
      this.onDetectedDockNotInRoom?.();
      logger.info("dock disconnected");
    }

    if (this.vehicleSid && !vehicleInRoom) {
      this.vehicleSid = "";
      this.onDetectedVehicleNotInRoom?.();
      logger.info("vehicle disconnected");
    }

    this.onParticipantsChanged?.(this.room?.participants);
  }

  public resetLivekitSkybusStatsReport() {
    this.livekitSkybusStatsReport = {
      sendStats: {},
      receiveStats: {},
      sendBeforeReadyStats: {},
    };
  }

  public addMessageToLivekitSkybusStatsReport(
    type: "sendStats" | "receiveStats" | "sendBeforeReadyStats",
    channelName: string,
    bytes: number,
    sid?: string
  ) {
    const timestamp = Date.now();
    if (!this.livekitSkybusStatsReport[type][sid || "BROADCAST"]) {
      this.livekitSkybusStatsReport[type][sid || "BROADCAST"] = {};
    }
    if (!this.livekitSkybusStatsReport[type][sid || "BROADCAST"][channelName]) {
      this.livekitSkybusStatsReport[type][sid || "BROADCAST"][channelName] = {
        numMessages: 1,
        lastMessageTimestamp: timestamp,
        bytes: bytes,
      };
    } else {
      this.livekitSkybusStatsReport[type][sid || "BROADCAST"][channelName]["numMessages"] += 1;
      this.livekitSkybusStatsReport[type][sid || "BROADCAST"][channelName]["bytes"] += bytes;
      this.livekitSkybusStatsReport[type][sid || "BROADCAST"][channelName]["lastMessageTimestamp"] =
        timestamp;
    }
  }
}
