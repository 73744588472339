import { createContext, forwardRef, useContext } from "react";
import { tv } from "tailwind-variants";

import { useControlledState } from "../../hooks/useControlledState";
import { cn } from "../../utils/cn";
import { Checkbox } from "../checkbox/Checkbox";
import { Skeleton } from "../skeleton/Skeleton";
import { Heading } from "../typography/Heading";

import type { FC, HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";
import type {
  ComponentWithAttachedSkeletonComponent,
  ForwardRefComponentWithSubcomponents,
} from "../../utils/types";

const borderClasses = [
  "rounded",
  "box-border",
  "border",
  "border-solid",
  "border-gray-50",
  "dark:border-gray-800",
  "transition-colors duration-200",
  "bg-white",
  "dark:bg-gray-850",
  "overflow-hidden",
];

const listVariants = tv({
  base: "flex flex-col",
  variants: {
    variant: {
      separate: "gap-y-3",
      stacked: [
        ...borderClasses,
        "divide-y",
        "divide-solid",
        "divide-gray-50",
        "dark:divide-gray-800",
      ],
    },
  },
  defaultVariants: {
    variant: "separate",
  },
});

export interface ListProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof listVariants> {
  compact?: boolean;
}

interface ListContext {
  variant: typeof listVariants.defaultVariants.variant;
  compact: boolean;
}

const listContext = createContext<ListContext>({
  variant: listVariants.defaultVariants.variant,
  compact: false,
});

export const List = forwardRef<HTMLDivElement, ListProps>(
  ({ className, variant = "separate", compact = false, ...props }, ref) => (
    <listContext.Provider value={{ variant, compact }}>
      <div ref={ref} className={listVariants({ variant, className })} {...props} />
    </listContext.Provider>
  )
) as ForwardRefComponentWithSubcomponents<HTMLDivElement, { Item: typeof ListItem }, ListProps>;
List.displayName = "List";

const listItemVariants = tv({
  base: "group relative flex max-w-full",
  variants: {
    variant: {
      separate: borderClasses,
      stacked: "border-x-0", // NOTE(sam): this is only necessary because Tailwind preflight is disabled
    },
    highlighted: {
      true: "border-blue-200 bg-blue-50 dark:border-blue-800 dark:bg-blue-900",
      false: "",
    },
  },
  defaultVariants: {
    variant: "separate",
    highlighted: false,
  },
});

export interface ListItemProps
  extends HTMLAttributes<HTMLDivElement>,
    Omit<VariantProps<typeof listItemVariants>, "variant"> {
  selectable?: boolean;
  selected?: boolean;
  onSelectedChange?: (selected: boolean) => void;
}

const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
  (
    {
      className,
      highlighted,
      selectable,
      selected: selectedProp,
      onSelectedChange,
      children,
      ...props
    },
    ref
  ) => {
    const { variant } = useContext(listContext);

    // NOTE(sam): If we have a lot of lists that use selection we might consider building some sort
    // of group context for managing it conveniently
    const [selected, setSelected] = useControlledState(selectedProp, false, onSelectedChange);

    return (
      <div
        ref={ref}
        className={listItemVariants({
          variant,
          highlighted: highlighted || selected,
          className,
        })}
        {...props}
      >
        {selectable && (
          <Checkbox
            className={cn(
              "opacity-0",
              "group-hover:opacity-100",
              "data-[selected=true]:opacity-100",
              "transition-opacity duration-200",
              "absolute",
              "left-2 top-2"
            )}
            size="lg"
            isSelected={selected}
            onValueChange={setSelected}
          />
        )}
        {children}
      </div>
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Thumbnail: typeof ListItemThumbnail;
    Content: typeof ListItemContent;
    Title: typeof ListItemTitle;
    Description: typeof ListItemDescription;
    Details: typeof ListItemDetails;
    Controls: typeof ListItemControls;
    Tags: typeof ListItemTags;
  },
  ListItemProps
>;
ListItem.displayName = "List.Item";
List.Item = ListItem;

const ListItemThumbnail: FC<HTMLAttributes<HTMLElement>> & {
  Skeleton: FC<HTMLAttributes<HTMLElement>>;
} = ({ className, ...props }) => (
  <div className={cn("flex shrink-0 items-center", className)} {...props} />
);
ListItemThumbnail.displayName = "List.Item.Thumbnail";
ListItem.Thumbnail = ListItemThumbnail;

const ListItemThumbnailSkeleton: FC<HTMLAttributes<HTMLElement>> = ({ className, ...props }) => (
  <Skeleton className={cn("size-24 rounded-none", className)} {...props} />
);

ListItemThumbnail.Skeleton = ListItemThumbnailSkeleton;

const listItemContentVariants = tv({
  base: "flex grow flex-col justify-center overflow-hidden",
  variants: {
    height: {
      default: "py-4",
      tall: "",
    },
    compact: {
      false: "gap-y-3 pl-4 last:pr-4",
      true: "gap-y-1 py-3 pl-2 last:pr-2",
    },
  },
  compoundVariants: [
    {
      height: "tall",
      compact: true,
      class: "py-4",
    },
    {
      height: "tall",
      compact: false,
      class: "py-7",
    },
  ],
  defaultVariants: {
    height: "default",
    compact: false,
  },
});

export interface ListItemContentProps
  extends HTMLAttributes<HTMLDivElement>,
    Omit<VariantProps<typeof listItemContentVariants>, "compact"> {}

const ListItemContent = forwardRef<HTMLDivElement, ListItemContentProps>(
  ({ className, height, ...props }, ref) => {
    const { compact } = useContext(listContext);

    return (
      <div
        ref={ref}
        className={listItemContentVariants({ height, compact, className })}
        {...props}
      />
    );
  }
);
ListItemContent.displayName = "List.Item.Content";
ListItem.Content = ListItemContent;

const listItemTitleVariants = tv({
  base: "truncate",
  variants: {
    size: {
      sm: "",
      lg: "",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

export interface ListItemTitleProps
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof listItemTitleVariants> {}

const ListItemTitle = forwardRef<HTMLHeadingElement, ListItemTitleProps>(
  ({ className, size = "lg", ...props }, ref) => {
    return <Heading ref={ref} level={size === "lg" ? 5 : 6} className={className} {...props} />;
  }
) as ComponentWithAttachedSkeletonComponent<
  HTMLHeadingElement,
  ListItemTitleProps,
  ListItemTitleSkeletonProps
>;
ListItemTitle.displayName = "List.Item.Title";
ListItem.Title = ListItemTitle;

const listItemTitleSkeletonVariants = tv({
  base: "w-1/2",
  variants: {
    size: {
      sm: "h-5",
      lg: "h-[22px]",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

interface ListItemTitleSkeletonProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof listItemTitleSkeletonVariants> {}

const ListItemTitleSkeleton = forwardRef<HTMLDivElement, ListItemTitleSkeletonProps>(
  ({ className, size, ...props }, ref) => (
    <Skeleton ref={ref} className={listItemTitleSkeletonVariants({ size, className })} {...props} />
  )
);
ListItemTitleSkeleton.displayName = "List.Item.Title.Skeleton";
ListItemTitle.Skeleton = ListItemTitleSkeleton;

const ListItemDescription = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
  ({ className, ...props }, ref) => (
    <span ref={ref} className={cn("block text-sm text-gray-300", className)} {...props} />
  )
) as ComponentWithAttachedSkeletonComponent<HTMLSpanElement>;
ListItemDescription.displayName = "List.Item.Description";
ListItem.Description = ListItemDescription;

const ListItemDescriptionSkeleton = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <Skeleton ref={ref} className={cn("my-0.5 h-4 w-2/3", className)} {...props} />
  )
);
ListItemDescriptionSkeleton.displayName = "List.Item.Description.Skeleton";
ListItemDescription.Skeleton = ListItemDescriptionSkeleton;

const ListItemDetails = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { noDots?: boolean }
>(({ className, noDots, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex",
      "flex-row",
      "text-xs",
      "h-3",
      "overflow-hidden",
      "flex-wrap",
      noDots
        ? "gap-x-2.5"
        : [
            "[&>*]:flex",
            "[&>*]:flex-row",
            "[&>*::before]:content-['']",
            "[&>*::before]:mx-2",
            "[&>*::before]:my-auto",
            "[&>*::before]:bg-gray-150",
            "[&>*::before]:h-1",
            "[&>*::before]:w-1",
            "[&>*::before]:rounded-full",
            "[&>*:first-child::before]:content-none",
          ],
      className
    )}
    {...props}
  />
));
ListItemDetails.displayName = "List.Item.Details";
ListItem.Details = ListItemDetails;

// TODO(sam): Implement responsiveness for this container when we implement tags
const ListItemTags = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn("mt-1.5 flex flex-row gap-x-2.5", className)} {...props} />
  )
);
ListItemTags.displayName = "List.Item.Tags";
ListItem.Tags = ListItemTags;

const listItemControlsVariants = tv({
  base: "flex flex-row items-center gap-x-2 pl-2",
  variants: {
    compact: {
      false: "py-4 pr-4",
      true: "py-3 pr-2",
    },
  },
  defaultVariants: {
    compact: false,
  },
});

interface ListItemControlsProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof listItemControlsVariants> {}

const ListItemControls = forwardRef<HTMLDivElement, ListItemControlsProps>(
  ({ className, ...props }, ref) => {
    const { compact } = useContext(listContext);

    return (
      <div ref={ref} className={listItemControlsVariants({ compact, className })} {...props} />
    );
  }
);
ListItemControls.displayName = "List.Item.Controls";
ListItem.Controls = ListItemControls;
