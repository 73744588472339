import _ from "lodash";
import * as THREE from "three";

import { VehicleSettings } from "@skydio/channels/src/update_vehicle_settings_pb";
import { FlightState } from "@skydio/pbtypes/pbtypes/gen/ambassador/flight_state_pb";
import {
  AllowedEdges,
  GimbalPitchMode,
  GraphMotionArgs,
  HeadingMode,
  HeightMode,
  LookAtMotionArgs,
  TraversalMotionArgs,
} from "@skydio/pbtypes/pbtypes/vehicle/skills/waypoints_pb";
import { cn } from "@skydio/rivet-ui";

import { TELEOP_ANT_ALERT_CONTAINER_ID } from "components/main/teleop/AlertManagerDisplay/constants";
import { RFD_HUD_COMPASS_ID } from "components/main/teleop/overlay/Compass/constants";
import { boolToNum, kMilliTol } from "utils/common";

import {
  CLOUD_SKILLS,
  GamepadActionInputs,
  GamepadInputType,
  GamepadMode,
  GamepadMovementInputs,
} from "./types";

import type { TransPb } from "@skydio/pbtypes/pbtypes/gen/body/trans_pb";
import type { UnstampedTransf } from "@skydio/pbtypes/pbtypes/gen/vehicle_stats_lite/unstamped_transf_pb";
import type {
  GamepadMovementInputInfo,
  MouseOrientation,
  MovementDirection,
  TeleopAction,
} from "./types";

// multiplier to determine position/ angular increment
// equivalent to how long we expect a keyboard tap to correspond to
export const TAP_DURATION = 0.2; // [s]
// timeout to stop sending desired pose commands that come from a single tap. It should only take
// TAP_DURATION for the drone to reach the desired pose but we add a buffer to account for latency
export const DESIRED_POSE_TIMEOUT = TAP_DURATION * 5; // [s]
// timeout to stop sending mouse commands after user moves the mouse (for pointer lock)
export const MOUSE_COMMAND_TIMEOUT = 1.0; // [s]

// max speed allowed with VIO
export const MAX_VISUAL_NAVIGATION_SPEED = 16.0; // [m/s]
// vehicle limit for translational speed
export const MAX_TRANSLATIONAL_SPEED = 20.0; // [m/s]
// max speed allowed with nightsense
const MAX_NIGHTSENSE_SPEED = 8.0; // [m/s]
// max speed allowed in twilight mode
export const MAX_TWLIGHT_MODE_SPEED = 14.0; // [m/s]

const speedSettingsToMatchControllerFlight = {
  [VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES]: {
    min: 0.5,
    max: MAX_VISUAL_NAVIGATION_SPEED,
    default: 5.0,
    step: 0.5,
    // multiplier so that 6.0 m/s corresponds to pi/3 rad/s (60 deg/s)
    translationToYaw: Math.PI / 18,
    // multiplier so that 6.0 m/s corresponds to -pi/4 rad/s (-45 deg/s)
    translationToPitch: -Math.PI / 24,
  },
  [VehicleSettings.ObstacleMarginMode.REDUCED_OBSTACLES]: {
    min: 0.25,
    max: 8.0,
    default: 2.0,
    step: 0.25,
    // since yawing and pitching doesn't increase the likelihood of collision, we can increase the
    // multiplier so that we don't slow down as much (speed is 2/5th of default, multiplier is 2x)
    translationToYaw: Math.PI / 9,
    translationToPitch: -Math.PI / 12,
  },
  [VehicleSettings.ObstacleMarginMode.MINIMAL_OBSTACLES]: {
    min: 0.25,
    max: 8.0,
    default: 2.0,
    step: 0.25,
    // since yawing and pitching doesn't increase the likelihood of collision, we can increase the
    // multiplier so that we don't slow down as much (speed is 2/5th of default, multiplier is 2x)
    translationToYaw: Math.PI / 9,
    translationToPitch: -Math.PI / 12,
  },
  [VehicleSettings.ObstacleMarginMode.NO_OBSTACLES]: {
    min: 0.5,
    max: MAX_VISUAL_NAVIGATION_SPEED,
    default: 5.0,
    step: 0.5,
    translationToYaw: Math.PI / 18,
    translationToPitch: -Math.PI / 24,
  },
};

const nightSenseSpeedSettings = {
  [VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES]: {
    ...speedSettingsToMatchControllerFlight[VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES],
    max: MAX_NIGHTSENSE_SPEED,
  },
  [VehicleSettings.ObstacleMarginMode.REDUCED_OBSTACLES]: {
    ...speedSettingsToMatchControllerFlight[VehicleSettings.ObstacleMarginMode.REDUCED_OBSTACLES],
    max: MAX_NIGHTSENSE_SPEED,
  },
  [VehicleSettings.ObstacleMarginMode.MINIMAL_OBSTACLES]: {
    ...speedSettingsToMatchControllerFlight[VehicleSettings.ObstacleMarginMode.MINIMAL_OBSTACLES],
    max: MAX_NIGHTSENSE_SPEED,
  },
  [VehicleSettings.ObstacleMarginMode.NO_OBSTACLES]: {
    ...speedSettingsToMatchControllerFlight[VehicleSettings.ObstacleMarginMode.NO_OBSTACLES],
    max: MAX_NIGHTSENSE_SPEED,
  },
};

// We can fly faster if we are flying only using GPS (no visual navigation) without OA enabled
const gpsNavigationSpeedSettings = {
  ...speedSettingsToMatchControllerFlight,
  [VehicleSettings.ObstacleMarginMode.NO_OBSTACLES]: {
    ...speedSettingsToMatchControllerFlight[VehicleSettings.ObstacleMarginMode.NO_OBSTACLES],
    max: MAX_TRANSLATIONAL_SPEED,
  },
};

// Speed settings for twilight mode with twilightModeFastFlightFeatureFlagEnabled. With this, the
// user can fly in twilight mode the default obstacle speeds.
const twilightModeFastFlightSpeedSettings = {
  ...speedSettingsToMatchControllerFlight,
  [VehicleSettings.ObstacleMarginMode.NO_OBSTACLES]: {
    ...speedSettingsToMatchControllerFlight[VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES],
    max: MAX_TWLIGHT_MODE_SPEED,
  },
};

export const getSpeedSettings = (
  nightSenseActive = false,
  gpsNavigation = false,
  twilightMode = false
) => {
  if (nightSenseActive) {
    return nightSenseSpeedSettings;
  } else if (gpsNavigation) {
    return gpsNavigationSpeedSettings;
  } else if (twilightMode) {
    return twilightModeFastFlightSpeedSettings;
  } else {
    return speedSettingsToMatchControllerFlight;
  }
};

// descent / ascent velocity limits
const MAX_DESCENT_SPEED = 3.5;
const MAX_ASCENT_SPEED = 10.0;

// NOTE(kristen): X10 FCS has a bug when descent speed is beyond -4 m/s.
// Clamp to within this limit.
const MIN_VELOCITY = new THREE.Vector3(
  -MAX_TRANSLATIONAL_SPEED,
  -MAX_TRANSLATIONAL_SPEED,
  -MAX_DESCENT_SPEED
);
const MAX_VELOCITY = new THREE.Vector3(
  MAX_TRANSLATIONAL_SPEED,
  MAX_TRANSLATIONAL_SPEED,
  MAX_ASCENT_SPEED
);

export const clampVelocityToVehicleLimits = (velocity: THREE.Vector3) => {
  return velocity.clamp(MIN_VELOCITY, MAX_VELOCITY);
};

// gimbal pitch limits
export const MIN_GIMBAL_PITCH_RADIANS = -Math.PI / 2;
export const MAX_GIMBAL_PITCH_RADIANS = Math.PI / 2;

// click to fly
export const CLICK_TO_FLY_RAY_LENGTH = 10; // [m]

// gamepad
export const GAMEPAD_DEADZONE = 0.05; // values below this are ignored

// used to map gamepad analog values to an "expo curve"
export const cubicDeadzoneFunction = (deadZone: number, expoLevel: number) => {
  /*
  Makes the input somewhere between linear and cubic, depending on the expoLevel.

  Args:
    deadZone: value below which the input is considered 0
    expoLevel: interpolates between a linear a cubic curve. Cubic when 0, linear when 1.

  Returns:
    A function with a single argument (number, [-1, 1]) and output (number, [-1, 1])
  */
  return (x: number) => {
    const sgn = x < 0 ? -1 : 1;
    if (Math.abs(x) < deadZone) {
      return 0;
    }
    const scaledX = (sgn * (Math.abs(x) - deadZone)) / (1 - deadZone);
    return expoLevel * scaledX + (1 - expoLevel) * Math.pow(scaledX, 3);
  };
};

// used to reduce pitch/yaw rate when zoomed in
// when 0 speed is not affected by zoom, when 1 then speed correction factor is 1/(zoomLevel)
const ZOOM_SENSITIVITY = 0.3;
export const getZoomCorrection = (zoomLevel: number) => {
  return 1 / (1 + ZOOM_SENSITIVITY * (zoomLevel - 1));
};

// makes it easy to iterate over the enum while also getting information about its type
export function* supportedGamepadMovementInputs(): IterableIterator<GamepadMovementInputInfo> {
  yield { input: GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_X, type: GamepadInputType.AXIS };
  yield { input: GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_Y, type: GamepadInputType.AXIS };
  yield { input: GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_X, type: GamepadInputType.AXIS };
  yield { input: GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_Y, type: GamepadInputType.AXIS };
  yield { input: GamepadMovementInputs.GAMEPAD_BUTTON_LT, type: GamepadInputType.BUTTON };
  yield { input: GamepadMovementInputs.GAMEPAD_BUTTON_RT, type: GamepadInputType.BUTTON };
}

// returns whether the gamepad mapping is supported by teleop
export const isGamepadSupported = (mapping: GamepadMappingType) => {
  // for now, we only support the standard mapping
  return mapping === "standard";
};

export const getZeroMovementInput = (keyboard: boolean): Record<MovementDirection, number> => {
  // for keyboard inputs we store the time the key was pressed so intitialize to -1. For gamepad, we
  // store the analog value so initialize to 0
  const defaultValue = keyboard ? -1 : 0;
  return {
    forwards: defaultValue,
    backwards: defaultValue,
    left: defaultValue,
    right: defaultValue,
    up: defaultValue,
    down: defaultValue,
    yawLeft: defaultValue,
    yawRight: defaultValue,
    gimbalUp: defaultValue,
    gimbalDown: defaultValue,
  };
};

// mapping that is robust to whether the input is an axis or a button
// first value in the array is the negative direction, second is the positive direction
// (note that for the right and left stick, positive y is down)
export const gamepadModeMappings: Record<
  GamepadMode,
  Record<GamepadMovementInputs, Array<MovementDirection>>
> = {
  [GamepadMode.MODE_1]: {
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_X]: ["yawLeft", "yawRight"],
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_Y]: ["forwards", "backwards"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_X]: ["left", "right"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_Y]: ["up", "down"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_LT]: ["gimbalDown"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_RT]: ["gimbalUp"],
  },
  [GamepadMode.MODE_2]: {
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_X]: ["yawLeft", "yawRight"],
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_Y]: ["up", "down"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_X]: ["left", "right"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_Y]: ["forwards", "backwards"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_LT]: ["gimbalDown"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_RT]: ["gimbalUp"],
  },
  [GamepadMode.MODE_3]: {
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_X]: ["left", "right"],
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_Y]: ["forwards", "backwards"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_X]: ["yawLeft", "yawRight"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_Y]: ["up", "down"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_LT]: ["gimbalDown"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_RT]: ["gimbalUp"],
  },
  [GamepadMode.VIDEO_GAME_MODE]: {
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_X]: ["left", "right"],
    [GamepadMovementInputs.GAMEPAD_AXES_LEFT_STICK_Y]: ["forwards", "backwards"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_X]: ["yawLeft", "yawRight"],
    [GamepadMovementInputs.GAMEPAD_AXES_RIGHT_STICK_Y]: ["gimbalUp", "gimbalDown"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_LT]: ["down"],
    [GamepadMovementInputs.GAMEPAD_BUTTON_RT]: ["up"],
  },
};

// makes it easy to iterate over the enum
export function* supportedGamepadActionInputs(): IterableIterator<GamepadActionInputs> {
  yield GamepadActionInputs.GAMEPAD_BUTTON_DPAD_UP;
  yield GamepadActionInputs.GAMEPAD_BUTTON_DPAD_DOWN;
  yield GamepadActionInputs.GAMEPAD_BUTTON_DPAD_LEFT;
  yield GamepadActionInputs.GAMEPAD_BUTTON_DPAD_RIGHT;
  yield GamepadActionInputs.GAMEPAD_BUTTON_Y;
  yield GamepadActionInputs.GAMEPAD_BUTTON_X;
  yield GamepadActionInputs.GAMEPAD_BUTTON_RB;
  yield GamepadActionInputs.GAMEPAD_BUTTON_LB;
  yield GamepadActionInputs.GAMEPAD_BUTTON_A;
  yield GamepadActionInputs.GAMEPAD_BUTTON_B;
}
// mapping for teleop actions
export const gamepadActionMappings: Record<GamepadActionInputs, TeleopAction> = {
  [GamepadActionInputs.GAMEPAD_BUTTON_DPAD_UP]: "zoomIn",
  [GamepadActionInputs.GAMEPAD_BUTTON_DPAD_DOWN]: "zoomOut",
  [GamepadActionInputs.GAMEPAD_BUTTON_DPAD_LEFT]: "workflowPrevious",
  [GamepadActionInputs.GAMEPAD_BUTTON_DPAD_RIGHT]: "workflowNext",
  [GamepadActionInputs.GAMEPAD_BUTTON_Y]: "toggleObstacleAvoidance",
  [GamepadActionInputs.GAMEPAD_BUTTON_X]: "takePhoto",
  [GamepadActionInputs.GAMEPAD_BUTTON_RB]: "crawl",
  [GamepadActionInputs.GAMEPAD_BUTTON_LB]: "boost",
  [GamepadActionInputs.GAMEPAD_BUTTON_A]: "returnToDock",
  [GamepadActionInputs.GAMEPAD_BUTTON_B]: "cancel",
};

export const getFlightStateName = (flightState: FlightState.Enum) =>
  _.findKey(FlightState.Enum, o => o === flightState);

export const obstacleSafetyToObstacleMode = (obstacleSafety: number) => {
  // Nominally these values should map as such:
  // -1 -> no obstacles
  // -0.5 -> minimal avoidance
  // 0 -> close avoidance
  // 1 -> standard avoidance
  // The following checks are implemented continuously since we'll get intermediate float values
  // while the state is transitioning
  if (_.isNumber(obstacleSafety)) {
    if (obstacleSafety <= -1.0) {
      return VehicleSettings.ObstacleMarginMode.NO_OBSTACLES;
    }
    if (obstacleSafety < 0.0) {
      return VehicleSettings.ObstacleMarginMode.MINIMAL_OBSTACLES;
    }
    if (obstacleSafety < 1.0) {
      return VehicleSettings.ObstacleMarginMode.REDUCED_OBSTACLES;
    }
  }
  return VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES;
};

export const getKeyboardMovementVector = (inputs: Record<MovementDirection, number>) =>
  new THREE.Vector3(
    boolToNum(inputs.forwards > 0) - boolToNum(inputs.backwards > 0), // x
    boolToNum(inputs.left > 0) - boolToNum(inputs.right > 0), // y
    boolToNum(inputs.up > 0) - boolToNum(inputs.down > 0) // z
  ).normalize();

export const getGamepadMovementVector = (inputs: Record<MovementDirection, number>) =>
  new THREE.Vector3(
    inputs.forwards - inputs.backwards, // x
    inputs.left - inputs.right, // y
    inputs.up - inputs.down // z
  );

export const calculateNavTCam = (
  nav_T_imu?: THREE.Object3D,
  imu_T_cam?: THREE.Object3D,
  nav_t_imu_stale?: boolean
) => {
  if (!imu_T_cam || !nav_T_imu || nav_t_imu_stale) {
    return undefined;
  }
  // multiply nav_T_imu to imu_t_cam to get nav_T_cam
  const matrixTransform = new THREE.Matrix4();
  matrixTransform.multiplyMatrices(nav_T_imu.matrix, imu_T_cam.matrix);
  const nav_T_cam = new THREE.Object3D();
  nav_T_cam.applyMatrix4(matrixTransform);
  return nav_T_cam;
};

// Rdf = right-down-forward, Flu = forward-left-up
const kRdf_R_Flu = new THREE.Quaternion(0.5, -0.5, 0.5, 0.5);
const kFlu_R_Rdf = kRdf_R_Flu.clone().invert();

const NAV_X = new THREE.Vector3(1, 0, 0);
const NAV_Y = new THREE.Vector3(0, 1, 0);

// Mutable objects used to avoid creating new instances every time we calculate heading/pitch
const FORWARD_MUTABLE = new THREE.Vector3();
const YPR_MUTABLE = new THREE.Euler();
const ORIENTATION_MUTABLE = new THREE.Quaternion();

export const calculateNavHeadingPitchFromQuaternion = (
  quaternion?: THREE.Quaternion
): MouseOrientation | undefined => {
  if (!quaternion) {
    return undefined;
  }
  // nav is in FLU, cam is in RDF so we need to do nav_T_cam * Rdf_R_Flu to get orientation in FLU
  const orientation = ORIENTATION_MUTABLE.copy(quaternion).multiply(kRdf_R_Flu);

  // Unpack orientation into euler angles
  YPR_MUTABLE.setFromQuaternion(orientation, "ZYX");

  // Pitch can be taken straight from the Euler angles
  const pitch = YPR_MUTABLE.y;

  FORWARD_MUTABLE.set(1, 0, 0);
  // In order to calculate heading, first we apply a counter pitch to the forward vector
  // to make sure it stays level after the gimbal orientation is applied
  FORWARD_MUTABLE.applyAxisAngle(NAV_Y, -pitch);
  // Then we can apply the orientation, so now it is pointing forward in NAV frame.
  // This effectively cancels out the pitch applied in the first step and so `FORWARD_MUTABLE` is only
  // changed by the heading and roll.
  FORWARD_MUTABLE.applyQuaternion(orientation);

  // Finally, we can calculate the heading angle
  const heading = FORWARD_MUTABLE.angleTo(NAV_X);

  // Cross product is used to determine the sign
  const cross = FORWARD_MUTABLE.cross(NAV_X);
  // Counter-clockwise or right-hand rule is used to determine the sign
  const signedHeading = cross.z < 0 ? heading : -heading;

  return {
    heading: signedHeading,
    pitch,
  };
};

export const calculateNavHeadingPitch = (
  nav_T_cam?: THREE.Object3D
): MouseOrientation | undefined => {
  if (!nav_T_cam) {
    return undefined;
  }
  return calculateNavHeadingPitchFromQuaternion(nav_T_cam.quaternion);
};

export const calculateImuTCamHeadingPitch = (imu_T_cam?: THREE.Object3D) => {
  if (!imu_T_cam) {
    return undefined;
  }
  // Need to do Flu_R_Rdf * imu_T_cam * Rdf_R_Flu to final angle in FLU, since both imu and cam are
  // in RDF
  const orientation = kFlu_R_Rdf
    .clone()
    .multiply(imu_T_cam.quaternion)
    .clone()
    .multiply(kRdf_R_Flu);
  const rpy = new THREE.Euler().setFromQuaternion(orientation, "ZYX");
  const headingPitch: MouseOrientation = {
    heading: rpy.z,
    pitch: rpy.y,
  };
  return headingPitch;
};

export const calculateDesiredPose = (
  movementVector: THREE.Vector3,
  headingIncrement: number,
  pitchIncrement: number,
  pointerLocked: boolean,
  vehiclePosition?: THREE.Vector3,
  navHeadingPitch?: MouseOrientation
) => {
  // set default as undefined and then fill if we get a meaningful increment
  let desiredPosition, desiredHeading, desiredPitch;
  // need a valid nav pose to compute desired pose
  if (vehiclePosition !== undefined && navHeadingPitch !== undefined) {
    // position
    if (movementVector.lengthSq() > kMilliTol) {
      // rotate position vector by the heading and add current position to get desiredPosition
      const rotatedMovementVector = movementVector.clone();
      rotatedMovementVector.applyAxisAngle(new THREE.Vector3(0, 0, 1), navHeadingPitch.heading);
      desiredPosition = vehiclePosition.clone().add(rotatedMovementVector);
    }
    // heading (only applicable if not in pointerLock)
    if (!pointerLocked && Math.abs(headingIncrement) > kMilliTol) {
      desiredHeading = navHeadingPitch.heading + headingIncrement;
    }
    // pitch (only applicable if not in pointerLock)
    if (!pointerLocked && Math.abs(pitchIncrement) > kMilliTol) {
      desiredPitch = _.clamp(
        navHeadingPitch.pitch + pitchIncrement,
        MIN_GIMBAL_PITCH_RADIANS,
        MAX_GIMBAL_PITCH_RADIANS
      );
    }
  }
  // return full pose
  return { position: desiredPosition, heading: desiredHeading, pitch: desiredPitch };
};

export const getDefaultTraversalArgs = (teleopSpeedSetting: number) => {
  const traversalArgs = new TraversalMotionArgs();
  traversalArgs.setAscendSpeed(teleopSpeedSetting);
  traversalArgs.setSpeed(teleopSpeedSetting);
  // rely on the vehicle to clip descent speed appropriately
  traversalArgs.setDescendSpeed(teleopSpeedSetting);
  traversalArgs.setHeightMode(HeightMode.Enum.GRADUAL);
  // use pathfinder by default to give us benefit of voxel-map based A-star planning
  traversalArgs.setUsePathfinder(true);
  return traversalArgs;
};

export const getDefaultGraphArgs = (teleopSpeedSetting: number) => {
  const graphArgs = new GraphMotionArgs();
  // get speed setting from teleop speed slider
  graphArgs.setSpeed(teleopSpeedSetting);
  graphArgs.setUseSpline(true);
  const allowedEdges = new AllowedEdges();
  allowedEdges.setVehiclePaths(true);
  allowedEdges.setLoadedPaths(true);
  graphArgs.setAllowedEdges(allowedEdges);
  return graphArgs;
};

export const getDefaultLookAtArgs = () => {
  const lookAtArgs = new LookAtMotionArgs();
  lookAtArgs.setHeadingMode(HeadingMode.Enum.GRADUAL);
  lookAtArgs.setGimbalPitchMode(GimbalPitchMode.Enum.GRADUAL);
  return lookAtArgs;
};

export const getTeleopAntMessageClassName = (): string => {
  // We don't have access to the feature flags in the pilot state slice, so this is the best heuristic we have
  const isAlertManagerEnabled = document.getElementById(TELEOP_ANT_ALERT_CONTAINER_ID) != null;
  const isRfdHudCompassEnabled = document.getElementById(RFD_HUD_COMPASS_ID) != null;

  return cn({
    "mt-16": isAlertManagerEnabled && isRfdHudCompassEnabled,
    "mt-20": !isAlertManagerEnabled && !isRfdHudCompassEnabled,
  });
};

export const toTransPb = (value: UnstampedTransf.AsObject, utime: number): TransPb.AsObject => {
  return {
    utime,
    orientation: value.orientation,
    position: value.position,
  };
};

// Support starting missions from the ground (e.g fly to point)
const SUPPORTED_SKILLS_ON_GROUND = [
  CLOUD_SKILLS.MANUAL,
  CLOUD_SKILLS.WAYPOINTS,
  CLOUD_SKILLS.MISSION_PLANNER,
];

export const isSkillSupported = (skillKey: string | undefined, isAirborne: boolean) => {
  if (!skillKey) {
    return undefined;
  }
  const groundSupportedSkill = SUPPORTED_SKILLS_ON_GROUND.includes(skillKey as CLOUD_SKILLS);

  const cloudSupportedSkill = Object.values(CLOUD_SKILLS).includes(skillKey as CLOUD_SKILLS);

  return isAirborne ? cloudSupportedSkill : groundSupportedSkill;
};
