import { faSearch, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mergeProps } from "@react-aria/utils";
import { createContext, forwardRef, useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { mergeRefs } from "../../utils/refs";
import { useInput } from "./useInput";

import type { FC, HTMLAttributes } from "react";
import type { DOMAttributes, ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { UseInputProps } from "./useInput";

export type InputProps = Omit<UseInputProps, "isMultiline">;

const inputContext = createContext<DOMAttributes<HTMLInputElement>>({});

const InputInner: FC<HTMLAttributes<HTMLInputElement>> = props => {
  const propsFromContext = useContext(inputContext);

  const allProps = mergeProps(props, propsFromContext);

  return <input {...allProps} />;
};

/**
 * This component renders a text input.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(({ children, ...props }, ref) => {
  const form = useFormContext();

  if (form && !props.name) {
    throw new Error("Input used inside a Form component must have a name prop");
  }

  const formError = form ? form.formState.errors[props.name!] : undefined;
  const formInputProps = form ? form.register(props.name!) : undefined;

  const {
    label,
    description,
    isClearable,
    startContent,
    endContent,
    labelPlacement,
    hasHelper,
    isOutsideLeft,
    errorMessage,
    baseProps,
    labelProps,
    inputProps,
    innerWrapperProps,
    inputWrapperProps,
    helperWrapperProps,
    descriptionProps,
    errorMessageProps,
    clearButtonProps,
    startContentProps,
  } = useInput({
    ...(formInputProps ? mergeProps(props, formInputProps) : props),
    isDisabled: formInputProps ? formInputProps.disabled : props.isDisabled,
    errorMessage: formError ? String(formError.message) : props.errorMessage,
    isInvalid: formError ? true : props.isInvalid,
    ref: formInputProps ? mergeRefs(ref, formInputProps.ref) : ref,
  });

  const labelContent = label ? <label {...labelProps}>{label}</label> : null;

  const end = useMemo(() => {
    if (isClearable) {
      return <span {...clearButtonProps}>{endContent || <FontAwesomeIcon icon={faXmark} />}</span>;
    }

    return endContent;
  }, [isClearable, clearButtonProps]);

  const helperWrapper = useMemo(() => {
    if (!hasHelper) return null;

    return (
      <div {...helperWrapperProps}>
        {errorMessage ? (
          <div {...errorMessageProps}>{errorMessage}</div>
        ) : description ? (
          <div {...descriptionProps}>{description}</div>
        ) : null}
      </div>
    );
  }, [
    hasHelper,
    errorMessage,
    description,
    helperWrapperProps,
    errorMessageProps,
    descriptionProps,
  ]);

  const innerWrapper = useMemo(() => {
    const inputElement = (
      <inputContext.Provider value={inputProps}>{children || <InputInner />}</inputContext.Provider>
    );

    if (startContent || end) {
      return (
        <div {...innerWrapperProps}>
          {startContent && <span {...startContentProps}>{startContent}</span>}
          {inputElement}
          {end}
        </div>
      );
    }

    return <div {...innerWrapperProps}>{inputElement}</div>;
  }, [children, startContent, end, inputProps, innerWrapperProps, startContentProps]);

  const mainWrapper = useMemo(() => {
    return (
      <>
        <div {...inputWrapperProps}>
          {labelContent}
          {innerWrapper}
        </div>
        {helperWrapper}
      </>
    );
  }, [
    labelPlacement,
    helperWrapper,
    labelContent,
    innerWrapper,
    errorMessage,
    description,
    inputWrapperProps,
    errorMessageProps,
    descriptionProps,
  ]);

  return (
    <div {...baseProps}>
      {isOutsideLeft ? labelContent : null}
      {mainWrapper}
    </div>
  );
}) as ForwardRefComponentWithSubcomponents<
  HTMLInputElement,
  {
    Input: typeof InputInner;
    Search: typeof SearchInput;
  },
  InputProps
>;
Input.displayName = "Input";

const SearchInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <Input
      placeholder="Search"
      startContent={<FontAwesomeIcon icon={faSearch} />}
      {...props}
      ref={ref}
    />
  );
});
SearchInput.displayName = "Input.Search";

Input.Input = InputInner;
Input.Search = SearchInput;
